<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Batch import items from CSV file</h3>

<p><span v-html="app_name"/> allows framework authors to import an existing framework from a spreadsheet (CSV or TSV file format) as a starting point to creating a framework. For ideas about how a framework can be organized, review the User Guide chapter for <a href="https://satchel.commongoodlt.com/" target="_blank">Authoring / Editing Standards</a>.</p>

<p>To import a framework from a file, you can use this <a href="https://docs.google.com/spreadsheets/d/1xv18eh52wCENzGVzELDJ1VYhFq_ociSRnqz9tiEjJR4" target="_blank">GOOGLE SHEET TEMPLATE</a> as a guide (or download into the file format of your choosing). The template has 2 tabs.:
    <ol type="1">
        <li>The <strong>Blank Template</strong> tab is for your reference to make the import process easier. You might need to tell the import wizard to ignore the header row of this template.</li>
        <li>The <strong>Exemplar</strong> tab provides column header definitions and an example of how a framework could be structured. (The Exemplar tab is the <a href="https://casenetwork.commongoodlt.com/c64961be-d7cb-11e8-824f-0242ac160002" target="_blank">Common Core English language Arts</a> standards formatted in Satchel-consumable CSV format.)</li>
    </ol>
</p>

<p>
    Prior to importing the file:
    <ul>
        <li>Fill in the Blank Template tab with the information needed for your framework (or manipulate your own spreadsheet to match the columns in the Blank Template tab). 
            <ul>
                <li>At a bare minimum, you will need the smart level and the full statement (this appears as the text of the domain, strand, cluster, or standard). </li>
                <li>It is <strong>recommended</strong> to add “Human Coding Scheme” values (aka Human-Readable Codes) where applicable.</li>
            </ul>
        </li>
        <li>Save the blank template tab as a CSV or TSV file</li>
        <li>Within Standards Satchel a System Admin will need to create a <a href="https://satchel.commongoodlt.com/" target="_blank">NEW FRAMEWORK</a> and give you Framework Admin or Framework Editor access (if you are a System Admin or System Editor you will already have access).</li>
    </ul>
</p>
<img srcset="/docimages/edit_framework_button.png 1.1x"  class="k-help-img float-right">
<p class="k-no-clear">To import your framework items from a file:
    <br>
    <br>
    <strong>Step 1: </strong>Open the framework you’d like to import into and enable editing. From the tree view, choose the top level of the framework to reveal the document card and select the EDIT FRAMEWORK button.
</p>

<br>
<br>
<br>

<img srcset="/docimages/create_child_items.png 1.1x"  class="k-help-img float-right">
<br>
<br>
<p class="k-no-clear"><strong>Step 2:</strong> From the Edit Framework menu, select CREATE/COPY/IMPORT TOP-LEVEL CHILD ITEMS option and BATCH IMPORT ITEMS FROM CSV FILE.</p>

<br>
<br>
<br>
<br>

<p><img srcset="/docimages/choose_new_csv.png 1.8x"  class="k-help-img float-right"></p>
<br>
<br>
<br>
<p class="k-no-clear"><strong>Step 3: Choose the CSV File</strong> from your computer for the import and begin matching the columns from your file (left side) to the possible CASE® items (right side). Select PROCESS LINES to view the imported items.</p>



<p><img srcset="/docimages/review_items.png 1.1x"  class="k-help-img float-right"></p>
<br>
<p class="k-no-clear"><strong>Step 4: </strong>A pop up window will appear to let you know how many new items (rows) from your file were processed. Your next step will be to review what has been imported to save or cancel your latest updates
    <ul>
        <li><strong>SAVE IMPORTED ITEMS</strong> will allow you to keep the imported items and then make edits as needed.</li>
        <li><strong>CANCEL IMPORT</strong> will wipe out the RED highlighted items from the framework.</li>
    </ul>
</p>

<br>
<br>
<br>

<p>Next steps may include <span v-html="link('move_item', 'moving items')"></span>, <span v-html="link('batch_update', 'batch updating items')"></span>, or even adding <span v-html="link('supplemental_info', 'supplemental information')"></span>. If you’re interested in purchasing services from Common Good Learning Tools to import / convert your frameworks from spreadsheets, docs, or PDFs (including consultation around CASE®), email <a href="contact@commongoodlt.com">contact@commongoodlt.com</a></p>
















</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>